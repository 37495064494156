import breadcrumbComp from "../../common/breadcrumb-comp";
import emailService from "./email-process-master-service";
import EncryptUtility from "../../utility/js/encrypt-utility";
import AddFavourite from "../../utility/js/addFav";
import Utility from "../../../shared/utility";
export default {
  data: () => ({
    max50Rule: [(v) => (v || "").length <= 50 || "Max Length of 50 character", (v) => !!v || "Field is required"],
    max256Rule: [(v) => (v || "").length <= 256 || "Max Length of 256 character", (v) => !!v || "Field is required"],
    userId: EncryptUtility.localStorageDecrypt("userID"),
    searchdisable: false,
    actionList: [],
    mainPage: "",
    favouriteList: [],
    pageUrl: "",
    subPage: "",
    showAdd: false,
    subPageUrl: "",
    backSlash: true,
    allowedit: false,
    backSlashParent: true,
    showRefreshDialog: true,
    refresh: true,
    isEditing: true,
    isFormValid: false,
    exportDisable: true,
    trapManagerEdit: false,
    totalRecords: 0,
    emailProcessSearch: "",
    emailProcessMasterList: [],
    emailProcessData: [],
    active: true,
    emailProcessEdit: false,
    processId: 0,
    allowedEdit: false,
    IsaddActive: true,
    addEmailProcessDialog: false,
    addprocess: "",
    addprocessdesciption: "",
    excelName: "",
    required: [(v) => !!v || "Field is required"],
    headers: [
      {
        text: "Process Name",
        align: "start",
        value: "process_name",
        class: "primary customwhite--text",
        width: "10%",
      },
      {
        text: "Process Description",
        value: "process_desc",
        class: "primary customwhite--text",
        width: "15%",
      },

      {
        text: "Status",
        value: "active",
        class: "primary customwhite--text",
        width: "7%",
      },
      {
        text: "Created By",
        value: "cby",
        class: "primary customwhite--text",
        width: "7%",
      },
      {
        text: "Created Date",
        value: "cdate",
        class: "primary customwhite--text",
        width: "7%",
      },
      {
        text: "Actions",
        value: "actions",
        class: "primary customwhite--text",
        sortable: false,
        width: "5%",
      },
    ],
    selectedStatus: "",
    statusselected: "",

    json_fields: {
      ProcessName: "process_name",
      ProcessDesciption: "process_desc",
      Status: "active",
      CreatedBy: "cby",
      CreatedDate: "cdate",
    },
    json_meta: [
      [
        {
          key: "charset",
          value: "utf-8",
        },
      ],
    ],
  }),
  async created() {
    this.route = this.$route.path.replace("/", "");
    let data = await AddFavourite.getPageAction(this.userId, this.route);
    this.actionList = data.actionList;
    this.mainPage = data.mainPage;
    this.favouriteList = data.favouriteList;
    this.pageUrl = data.pageUrl;
    this.subPage = data.subPage;
    this.showAdd = data.showAdd;
  },
  mounted() {
    this.emailProcessSearchMeth();
  },
  methods: {
    //File name
    fileName() {
      this.excelName =
        "Email_Process_Master_" + new Date().toISOString().replace("T", "_").replace("Z", "").replace(".", "").replaceAll("-", "_") + ".xls";
    },
    actionSetter(action) {
      if (this.actionList !== null && this.actionList.length > 0) {
        let filter = this.actionList.filter((entry) => entry.SystemName === action);
        return filter !== undefined && filter !== null && filter.length == 1 ? true : false;
      } else return false;
    },
    //Reset Function
    resetFunction() {
      this.emailProcessSearch = "";
      this.active = true;
      this.emailProcessSearchMeth();
    },
    addFav() {
      this.showAdd = !this.showAdd;
      AddFavourite.add_remove_Favourite(this.showAdd, this.userId, this.route);
    },
    async emailProcessSearchMeth() {
      this.emailProcessData = [];
      let searchObj = {
        UserId: parseInt(this.userId),
        search_string: this.emailProcessSearch,
        pass: 1,
        active: this.active == true ? 1 : 0,
      };
      let searchData = await emailService.postEmailProcessPost("post", searchObj);
      if (searchData.message != "NA") {
        const newArr = searchData.map((obj) => {
          return {
            ...obj,
            cdate: Utility.convertESTToLocal(obj.cdate),
          };
        });
        this.emailProcessMasterList = newArr.filter((obj) => obj.process_name !== "" && obj.process_name !== undefined);
        this.exportDisable = false;
        this.totalRecords = this.emailProcessMasterList.length;
      } else {
        this.exportDisable = true;
        this.emailProcessMasterList = [];
        this.totalRecords = 0;
      }
    },
    async editItem(item) {
      this.allowedit = false;
      let editObj = {
        UserId: parseInt(this.userId),
        pass: 4,
        process_id: item.process_id,
      };
      this.processId = item.process_id;
      let emailDetailsData = await emailService.postEmailProcessPost("post", editObj);
      this.emailProcessEdit = true;
      this.editProcessName = emailDetailsData[0].process_name;
      this.editProcessDesc = emailDetailsData[0].process_desc;
      this.IsActive = emailDetailsData[0].active;
    },
    onClickCancel() {
      this.emailProcessEdit = false;
      this.$refs.EmailProcessEditForm.resetValidation();
    },
    onClickAddCancel() {
      this.addprocess = "";
      this.addprocessdesciption = "";
      this.IsaddActive = true;
      this.addEmailProcessDialog = false;
      this.$refs.addEmailProcessForm.resetValidation();
    },
    onClickUpdate() {
      if (this.$refs.EmailProcessEditForm.validate()) {
        let LoaderDialog = {
          visible: true,
          title: "Please Wait...",
        };
        this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        let editData = {
          active: this.IsActive == true ? 1 : 0,
          process_id: this.processId,
          process_name: this.editProcessName,
          process_desc: this.editProcessDesc,
          UserId: parseInt(this.userId),
          pass: 3,
        };
        {
          this.axios
            .post("/em/email_process_master", editData)
            .then((response) => {
              if (response.status == 200 || response.status == 204) {
                LoaderDialog.visible = false;
                this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
                let Alert = {
                  type: "success",
                  isShow: true,
                  message: response.data.body.message,
                };
                this.emailProcessEdit = false;
                this.emailProcessSearchMeth();
                this.$refs.EmailProcessEditForm.resetValidation();
                this.$store.commit("ConfigModule/Alert", Alert);
              } else {
                LoaderDialog.visible = false;
                this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
                let Alert = {
                  type: "error",
                  isShow: true,
                  message: response.data.message,
                };
                this.$store.commit("ConfigModule/Alert", Alert);
              }
              LoaderDialog.visible = false;
              this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            })
            .catch((error) => {
              LoaderDialog.visible = false;
              this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
              let Alert = {
                type: "error",
                isShow: true,
                message: Utility.apiError(error),
              };
              this.$store.commit("ConfigModule/Alert", Alert);
              this.errorMessage = error.message;
              this.emailProcessEdit = false;
              this.emailProcessSearchMeth();
              this.$refs.EmailProcessEditForm.resetValidation();
            });
        }
      }
    },
    closeDialog() {
      this.$refs.EmailProcessEditForm.resetValidation();
      this.emailProcessEdit = false;
    },
    closeaddDialog() {
      this.addprocess = "";
      this.addprocessdesciption = "";
      this.IsaddActive = true;
      this.addEmailProcessDialog = false;
      this.$refs.addEmailProcessForm.resetValidation();
    },
    allowEdit() {
      this.allowedEdit = true;
      this.allowedit = true;
    },
    onNewEmailProcessClick() {
      this.addprocess = "";
      this.addprocessdesciption = "";
      this.IsaddActive = true;
      this.addEmailProcessDialog = true;
    },
    clickAddEmailProcess() {
      if (this.$refs.addEmailProcessForm.validate()) {
        let LoaderDialog = {
          visible: true,
          title: "Please Wait...",
        };
        this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        let addObj = {
          UserId: parseInt(this.userId),
          pass: 2,
          active: this.IsaddActive == true ? 1 : 0,
          process_name: this.addprocess,
          process_desc: this.addprocessdesciption,
        };
        {
          this.axios
            .post("/em/email_process_master", addObj)
            .then((response) => {
              if (response.status == 200 || response.status == 204) {
                LoaderDialog.visible = false;
                this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
                let Alert = {
                  type: "success",
                  isShow: true,
                  message: response.data.body.message,
                };
                this.$store.commit("ConfigModule/Alert", Alert);
                this.$refs.addEmailProcessForm.resetValidation();
                this.emailProcessSearchMeth();
                this.addEmailProcessDialog = false;
              } else {
                LoaderDialog.visible = false;
                this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
                let Alert = {
                  type: "error",
                  isShow: true,
                  message: response.data.message,
                };
                this.$store.commit("ConfigModule/Alert", Alert);
              }
              LoaderDialog.visible = false;
              this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
              this.addprocess = "";
              this.addprocessdesciption = "";
              this.IsaddActive = true;
              this.$refs.addEmailProcessForm.resetValidation();
            })
            .catch((error) => {
              LoaderDialog.visible = false;
              this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
              let Alert = {
                type: "error",
                isShow: true,
                message: Utility.apiError(error),
              };
              this.$refs.addEmailProcessForm.resetValidation();
              this.$store.commit("ConfigModule/Alert", Alert);
              this.errorMessage = error.message;
            });
        }
      }
    },
  },

  components: {
    breadcrumbComp,
  },
};
